// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: (next) => path(ROOTS_AUTH, next ? `/login?next=${next}` : '/login'),
  logout: path(ROOTS_AUTH, '/logout'),
  register: (next) =>
    path(ROOTS_AUTH, next ? `/register?next=${next}` : '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password')
}

export const PATH_PAGE = {
  placeholder: {
    comingSoon: '/placeholder/coming-soon',
    maintenance: '/placeholder/maintenance'
  },
  landing: '/',
  about: '/about',
  // landing pages
  elearningMain: '/e-learning',
  bootcampMain: '/bootcamp',
  mentoringMain: '/mentoring',
  corporateService: '/corporate-service',
  corporateTraining: '/corporate-training',
  experience: '/experience',
  // blog
  blog: 'https://blog.myskill.id',
  // payment
  payment: (productType, productId) => `/payment/${productType}/${productId}`,
  invoice: (id) => `/payment/invoice/${id}`,
  thankYou: (id) => `/payment/invoice/${id}/thank-you`,
  redeemVoucher: '/payment/voucher/',
  // elearning
  course: (slug) => `/course/${slug}`,
  topic: (slug) => `/topic/${slug}`,
  learningPath: (slug) => `/learning-path/${slug}`,
  // bootcamp
  bootcamp: (slug) => `/bootcamp/${slug}`,
  bootcampBatch: (batchId) => `/bootcamp/batch/${batchId}`,
  // mentoring
  mentoring: {
    reviewMain: '/mentoring/review',
    review: { myReview: '/mentoring/review/my-review' }
  },
  // profile
  profile: {
    root: '/profile',
    info: '/profile/my-profile',
    transaction: '/profile/my-transaction',
    activity: '/profile/my-activity',
    purchase: '/profile/my-purchase'
  },
  legal: {
    privacyPolicy: '/legal/privacy-policy',
    termsAndCondition: '/legal/terms-and-conditions'
  },
  error: {
    403: (from) => (from ? `/403?from=${from}` : '/403'),
    404: (from) => (from ? `/404?from=${from}` : '/404'),
    500: (from) => (from ? `/500?from=${from}` : '/500'),
    general: (from) => (from ? `/error?from=${from}` : '/error')
  }
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // E-LEARNING
  learningPath: {
    root: path(ROOTS_DASHBOARD, '/learning-path'),
    list: path(ROOTS_DASHBOARD, '/learning-path/list'),
    create: path(ROOTS_DASHBOARD, '/learning-path/create'),
    update: (id) => path(ROOTS_DASHBOARD, `/learning-path/${id}/update`)
  },
  topic: {
    root: path(ROOTS_DASHBOARD, '/topic'),
    list: path(ROOTS_DASHBOARD, '/topic/list'),
    create: path(ROOTS_DASHBOARD, '/topic/create'),
    update: (id) => path(ROOTS_DASHBOARD, `/topic/${id}/update`)
  },
  course: {
    root: path(ROOTS_DASHBOARD, '/course'),
    list: path(ROOTS_DASHBOARD, '/course/list'),
    create: path(ROOTS_DASHBOARD, '/course/create'),
    update: (id) => path(ROOTS_DASHBOARD, `/course/${id}/update`),
    videos: (id) => path(ROOTS_DASHBOARD, `/course/${id}/videos`),
    preTest: (id) => path(ROOTS_DASHBOARD, `/course/${id}/pre-test`),
    postTest: (id) => path(ROOTS_DASHBOARD, `/course/${id}/post-test`),
    initializeQuiz: (id) =>
      path(ROOTS_DASHBOARD, `/course/${id}/initialize-quiz`)
  },
  courseProgress: {
    root: path(ROOTS_DASHBOARD, '/course-progress'),
    list: path(ROOTS_DASHBOARD, '/course-progress/list'),
    detail: (id) => path(ROOTS_DASHBOARD, `/course-progress/${id}`)
  },
  topicProgress: {
    root: path(ROOTS_DASHBOARD, '/topic-progress'),
    list: path(ROOTS_DASHBOARD, '/topic-progress/list'),
    detail: (id) => path(ROOTS_DASHBOARD, `/topic-progress/${id}`)
  },
  learningPathProgress: {
    root: path(ROOTS_DASHBOARD, '/learning-path-progress'),
    list: path(ROOTS_DASHBOARD, '/learning-path-progress/list'),
    detail: (id) => path(ROOTS_DASHBOARD, `/learning-path-progress/${id}`)
  },
  coursePortofolio: {
    root: path(ROOTS_DASHBOARD, '/course-portofolio'),
    list: path(ROOTS_DASHBOARD, '/course-portofolio/list')
  },
  elearningReport: path(ROOTS_DASHBOARD, '/elearning-report'),
  // BOOTCAMP
  bootcamp: {
    root: path(ROOTS_DASHBOARD, '/bootcamp'),
    list: path(ROOTS_DASHBOARD, '/bootcamp/list'),
    create: path(ROOTS_DASHBOARD, '/bootcamp/create'),
    update: (id) => path(ROOTS_DASHBOARD, `/bootcamp/${id}/update`),
    batch: {
      root: (id) => path(ROOTS_DASHBOARD, `/bootcamp/${id}/batch`),
      list: (id) => path(ROOTS_DASHBOARD, `/bootcamp/${id}/batch/list`),
      create: (id) => path(ROOTS_DASHBOARD, `/bootcamp/${id}/batch/create`),
      update: (bootcampId, bootcampBatchId) =>
        path(
          ROOTS_DASHBOARD,
          `/bootcamp/${bootcampId}/batch/${bootcampBatchId}/update`
        ),
      participantList: (bootcampId, bootcampBatchId) =>
        path(
          ROOTS_DASHBOARD,
          `/bootcamp/${bootcampId}/batch/${bootcampBatchId}/participant/list`
        ),
      participantCertificateList: (bootcampId, bootcampBatchId) =>
        path(
          ROOTS_DASHBOARD,
          `/bootcamp/${bootcampId}/batch/${bootcampBatchId}/participant/certificate-list`
        ),
      participantDetail: (bootcampId, bootcampBatchId, participantId) =>
        path(
          ROOTS_DASHBOARD,
          `/bootcamp/${bootcampId}/batch/${bootcampBatchId}/participant/${participantId}`
        )
    }
  },
  // MENTORING
  mentoring: {
    review: {
      root: path(ROOTS_DASHBOARD, '/mentoring/review'),
      respond: (reviewId) =>
        path(ROOTS_DASHBOARD, `/mentoring/review/${reviewId}/respond`),
      list: path(ROOTS_DASHBOARD, '/mentoring/review/list')
    }
  },
  // PAYMENT
  coupon: {
    root: path(ROOTS_DASHBOARD, '/coupon'),
    list: path(ROOTS_DASHBOARD, '/coupon/list'),
    create: path(ROOTS_DASHBOARD, '/coupon/create'),
    update: (id) => path(ROOTS_DASHBOARD, `/coupon/${id}/update`)
  },
  voucher: {
    root: path(ROOTS_DASHBOARD, '/voucher'),
    list: path(ROOTS_DASHBOARD, '/voucher/list'),
    create: path(ROOTS_DASHBOARD, '/voucher/create'),
    view: (id) => path(ROOTS_DASHBOARD, `/voucher/${id}`)
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    create: path(ROOTS_DASHBOARD, '/invoice/create'),
    createCustom: path(ROOTS_DASHBOARD, '/invoice/create/custom'),
    export: path(ROOTS_DASHBOARD, '/invoice/export'),
    productAccess: path(ROOTS_DASHBOARD, '/invoice/product-access'),
    bulkProductAccess: path(ROOTS_DASHBOARD, '/invoice/product-access/bulk'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`)
  },
  // ANALYTICS
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics'),
    transactionElearning: path(
      ROOTS_DASHBOARD,
      '/analytics/transaction-elearning'
    ),
    transactionBootcamp: path(
      ROOTS_DASHBOARD,
      '/analytics/transaction-bootcamp'
    ),
    transactionMentoringReview: path(
      ROOTS_DASHBOARD,
      '/analytics/transaction-review'
    ),
    transactionStats: path(ROOTS_DASHBOARD, '/analytics/transaction-statistics')
  },
  subscription: {
    elearning: {
      root: path(ROOTS_DASHBOARD, '/subscription/e-learning'),
      list: path(ROOTS_DASHBOARD, '/subscription/e-learning/list'),
      view: (id) => path(ROOTS_DASHBOARD, `/subscription/e-learning/${id}`)
    },
    review: {
      root: path(ROOTS_DASHBOARD, '/subscription/review'),
      list: path(ROOTS_DASHBOARD, '/subscription/review/list'),
      view: (id) => path(ROOTS_DASHBOARD, `/subscription/review/${id}`)
    }
  },
  // PACKAGE
  package: {
    elearning: {
      root: path(ROOTS_DASHBOARD, '/package/e-learning'),
      list: path(ROOTS_DASHBOARD, '/package/e-learning/list'),
      create: path(ROOTS_DASHBOARD, '/package/e-learning/create'),
      update: (id) => path(ROOTS_DASHBOARD, `/package/e-learning/${id}/update`)
    },
    review: {
      root: path(ROOTS_DASHBOARD, '/package/review'),
      list: path(ROOTS_DASHBOARD, '/package/review/list'),
      create: path(ROOTS_DASHBOARD, '/package/review/create'),
      update: (id) => path(ROOTS_DASHBOARD, `/package/review/${id}/update`)
    }
  },
  // User
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    create: path(ROOTS_DASHBOARD, '/user/create'),
    export: path(ROOTS_DASHBOARD, '/user/export'),
    register: path(ROOTS_DASHBOARD, '/user/register'),
    update: (id) => path(ROOTS_DASHBOARD, `/user/${id}/update`)
  },
  // feedback
  feedback: {
    root: path(ROOTS_DASHBOARD, '/feedback'),
    list: path(ROOTS_DASHBOARD, '/feedback/list'),
    aggregate: path(ROOTS_DASHBOARD, '/feedback/aggregate')
  }
}
