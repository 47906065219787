import { useState, useEffect } from 'react'

// ----------------------------------------------------------------------

export default function useOffSetTop(top) {
  const [offsetTop, setOffSetTop] = useState(false)
  const isTop = top || 100

  useEffect(() => {
    if (typeof window === 'undefined') return

    const eventListener = window.addEventListener('scroll', () => {
      if (window.pageYOffset > isTop) {
        setOffSetTop(true)
      } else {
        setOffSetTop(false)
      }
    })

    return () => {
      window.removeEventListener('scroll', eventListener)
    }
  }, [isTop])

  return offsetTop
}

// Usage
// const offset = useOffSetTop(100);
