export { default as NavSectionVertical } from './vertical'
export { default as NavSectionHorizontal } from './horizontal'

// ----------------------------------------------------------------------

/**
 * @param {string} path
 * @returns {boolean}
 */
export function isExternalLink(path) {
  return path.includes('http')
}

/**
 * @param {string} path reference path
 * @param {string} pathname pathname from router
 * @param {string} asPath asPath from router
 * @returns {boolean}
 */
export function getActive(path, pathname, asPath) {
  const checkPath = path.startsWith('#')

  return (
    (!checkPath && pathname.includes(path)) ||
    (!checkPath && asPath.includes(path))
  )
}
