// react
import { useState } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { List, Collapse, Link } from '@mui/material'
//
import NavItem from './NavItem'
import { getActive, isExternalLink } from '..'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.data
 * @param {number} props.depth
 * @param {boolean} props.hasChildren
 * @param {boolean} props.isCollapse
 * @returns {JSX.Element}
 */
export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false
}) {
  const { pathname, asPath, push } = useRouter()

  const active = getActive(data.path, pathname, asPath)

  const [open, setOpen] = useState(active)

  const handleClickItem = () => {
    if (!hasChildren) {
      push(data.path)
    }
    setOpen(!open)
  }

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link
          href={data.path}
          target='_blank'
          rel='noopener noreferrer'
          underline='none'
        >
          <NavItem
            item={data}
            depth={depth}
            open={open}
            active={active}
            isCollapse={isCollapse}
          />
        </Link>
      ) : (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
        />
      )}

      {!isCollapse && hasChildren && (
        <Collapse
          in={open}
          unmountOnExit
        >
          <List
            component='div'
            disablePadding
          >
            <NavSubList
              data={data.children}
              depth={depth}
            />
          </List>
        </Collapse>
      )}
    </>
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object[]} props.data
 * @param {number} props.depth
 * @returns {JSX.Element}
 */
function NavSubList({ data, depth }) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={Boolean(list.children)}
        />
      ))}
    </>
  )
}
