import { useMemo, useState } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material'
// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from '~/routes/paths'
// hooks
import useAuth from '~/hooks/useAuth'
// components
import NextLink from '~/components/NextLink'
import MyAvatar from '~/components/MyAvatar'
import MenuPopover from '~/components/MenuPopover'
import { IconButtonAnimate } from '~/components/animate'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function AccountPopover() {
  // menu list by role
  const { user } = useAuth()
  const { role } = user ?? {}

  const menuOptions = useMemo(() => {
    if (role?.startsWith('admin')) {
      return [
        { text: 'Profil', href: PATH_PAGE.profile.root },
        { text: 'Dashboard', href: PATH_DASHBOARD.root }
      ]
    }

    return [{ text: 'Profil', href: PATH_PAGE.profile.root }]
  }, [role])

  // popover handler
  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <MyAvatar sx={{ width: 35, height: 35 }} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant='subtitle2'
            noWrap
          >
            {user?.name}
          </Typography>
          <Typography
            variant='body2'
            sx={{ color: 'text.secondary' }}
            noWrap
          >
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {menuOptions?.map((option) => (
            <MenuItem
              key={option.text}
              href={option.href}
              component={NextLink}
              onClick={handleClose}
            >
              {option.text}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          component={NextLink}
          href={PATH_AUTH.logout}
          onClick={handleClose}
          sx={{ margin: 1 }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}
