// react
import { forwardRef } from 'react'
// @mui
import { Box, Tooltip, ListItemText, ListItemIcon } from '@mui/material'
import {
  ChevronRightOutlined,
  ExpandMoreOutlined,
  InfoOutlined
} from '@mui/icons-material'
// config
import { LAYOUT_ICON } from '~/config'
// components
import { ListItemStyle } from './style'

// ----------------------------------------------------------------------

const NavItem = forwardRef(({ item, depth, active, open, ...other }, ref) => {
  const { title, icon, info, children, disabled, caption /* , roles */ } = item

  const renderContent = (
    <ListItemStyle
      ref={ref}
      open={open}
      depth={depth}
      active={active}
      disabled={disabled}
      {...other}
    >
      {icon && (
        <ListItemIcon
          sx={{
            mr: 1,
            flexShrink: 0,
            width: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL,
            height: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      <ListItemText
        primary={title}
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2'
        }}
      />

      {caption && (
        <Tooltip
          title={caption}
          arrow
        >
          <Box
            component='span'
            sx={{ ml: 0.5, lineHeight: 0 }}
          >
            <InfoOutlined
              sx={{
                width: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL / -4,
                height: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL / -4
              }}
            />
          </Box>
        </Tooltip>
      )}

      {info && (
        <Box
          component='span'
          sx={{ ml: 1, lineHeight: 0 }}
        >
          {info}
        </Box>
      )}

      {Boolean(children) &&
        (depth > 1 ? (
          <ChevronRightOutlined
            sx={{
              ml: 0.5,
              flexShrink: 0,
              width: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL,
              height: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL
            }}
          />
        ) : (
          <ExpandMoreOutlined
            sx={{
              ml: 0.5,
              flexShrink: 0,
              width: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL,
              height: LAYOUT_ICON.NAVBAR_ITEM_HORIZONTAL
            }}
          />
        ))}
    </ListItemStyle>
  )

  return { renderContent }
})

NavItem.displayName = 'NavItem'

export default NavItem
