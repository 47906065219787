// react
import { forwardRef } from 'react'
// next
import Link from 'next/link'

/**
 * @param {Object?} props
 * @param {boolean?} props.prefetch - default `false`
 * @returns {JSX.Element}
 */
const NextLink = forwardRef(({ prefetch = false, ...rest }, ref) => {
  return (
    <Link
      ref={ref}
      prefetch={prefetch}
      {...rest}
    />
  )
})

NextLink.displayName = 'NextLink'

export default NextLink
