import { forwardRef } from 'react'
// component
import NextLink from '~/components/NextLink'
import NextImage from '~/components/NextImage'
// images
import MySkillLogo from '~/assets/logo/myskill-logo.png'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...rest }, ref) => {
  const logo = (
    <NextImage
      ref={ref}
      src={MySkillLogo}
      alt='MySkill Logo'
      placeholder='empty'
      nextHeight={24}
      sx={{ height: '1.5rem', ...sx }}
      {...rest}
    />
  )

  if (disabledLink) return logo

  return <NextLink href='/'>{logo}</NextLink>
})

Logo.displayName = 'Logo'

export default Logo
