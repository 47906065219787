// next
import Image from 'next/image'
// mui
import { styled } from '@mui/material/styles'
import { useState } from 'react'

// --------------------------------------------------------------------------------------------
// 1. Pick and define important props from NextImage

/**
 * @link {https://github.com/vercel/next.js/blob/canary/packages/next/src/shared/lib/get-img-props.ts#L25}
 */
// const NEXT_IMAGE_DEPRECATED_PROPS = [
//   'onLoadingComplete',
//   'layout',
//   'objectFit',
//   'objectPosition',
//   'lazyBoundary',
//   'lazyRoot'
// ]

const NEXT_IMAGE_SPECIAL_PROPS = [
  'src',
  'alt',
  'fill',
  'loader',
  'quality',
  'priority',
  'loading',
  'placeholder',
  'blurDataURL',
  'unoptimized',
  'overrideSrc',
  // proxy props for next/image
  'nextWidth',
  'nextHeight'
]

// --------------------------------------------------------------------------------------------
// 2. Create a proxied NextImage component,
// with following props renamed to avoid conflict with MUI's props:
// - width -> nextWidth
// - height -> nextHeight

/**
 * @typedef {Object} ProxiedNextImageProps
 * @extends {import('next/image').ImageProps} props
 * @property {number} props.nextWidth
 * @property {number} props.nextHeight
 */

/**
 * @param {ProxiedNextImageProps} props
 */
function ProxiedNextImage({ nextWidth, nextHeight, ...props }) {
  return (
    <Image
      {...props}
      width={nextWidth}
      height={nextHeight}
    />
  )
}

// --------------------------------------------------------------------------------------------
// 3. Create a MUI component using styled(),
// to allow styiling with MUI's props

const MuiNextImage = styled(ProxiedNextImage, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => NEXT_IMAGE_SPECIAL_PROPS.includes(prop),
  name: 'MuiNextImage',
  slot: 'Root'
})({})

/**
 * @typedef {Object} NextImageProps
 * @extends {ProxiedNextImageProps}
 * @extends {import('@mui/material').StyledComponentProps<'Root'>}
 */

/**
 * @param {NextImageProps} props
 * @param {string} props.src
 * @param {string} props.alt
 * @param {string} [props.placeholder='blur']
 * @param {number} [props.width] - deprecated, use `sx.width` instead
 * @param {number} [props.height] - deprecated, use `sx.height` instead
 * @returns {JSX.Element}
 */
export default function NextImage({
  src,
  alt,
  placeholder = 'blur',
  // for backward compatibility
  // pass width and height inside sx prop
  width,
  height,
  ...rest
}) {
  const [isLoaded, setIsLoaded] = useState(false)

  const combinedSx = {
    width,
    height,
    ...rest.sx
  }

  return (
    <MuiNextImage
      src={src}
      alt={alt}
      placeholder={isLoaded ? 'empty' : placeholder}
      onLoad={() => setIsLoaded(true)}
      sx={combinedSx}
      {...rest}
    />
  )
}
