// @mui
import { List, Box } from '@mui/material'
//
import { ListSubheaderStyle } from './style'
import NavList from './NavList'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object[]} props.navConfig
 * @param {boolean} props.isCollapse
 * @returns {JSX.Element}
 */
export default function NavSectionVertical({
  navConfig,
  isCollapse,
  ...other
}) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List
          key={group.subheader}
          disablePadding
          sx={{ px: 2 }}
        >
          <ListSubheaderStyle
            disableGutters
            sx={{
              ...(isCollapse && {
                opacity: 0
              })
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={Boolean(list.children)}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  )
}
