// @mui
import { Box, Tooltip } from '@mui/material'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
//
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {number} props.depth
 * @param {boolean} props.active
 * @param {boolean} props.open
 * @param {boolean} props.isCollapse
 * @returns {JSX.Element}
 */
export default function NavItem({
  item,
  depth,
  active,
  open,
  isCollapse,
  ...other
}) {
  const { title, icon, info, children, disabled, caption /*, roles */ } = item

  const renderContent = (
    <ListItemStyle
      depth={depth}
      active={active}
      disabled={disabled}
      {...other}
    >
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

      {depth !== 1 && <DotIcon active={active && depth !== 1} />}

      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={title}
        secondary={
          caption && (
            <Tooltip
              title={caption}
              placement='top-start'
            >
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2'
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption'
        }}
      />

      {!isCollapse && (
        <>
          {info && (
            <Box
              component='span'
              sx={{ lineHeight: 0 }}
            >
              {info}
            </Box>
          )}

          {Boolean(children) &&
            (open ? (
              <ExpandLessOutlined
                sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
              />
            ) : (
              <ExpandMoreOutlined
                sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
              />
            ))}
        </>
      )}
    </ListItemStyle>
  )

  return renderContent
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.active
 * @returns {JSX.Element}
 */
export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component='span'
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main'
          })
        }}
      />
    </ListItemIconStyle>
  )
}
