// react
import { forwardRef } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import { Avatar as MUIAvatar } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} [props.color='default']
 * @param {React.ReactNode} props.children
 * @param {Object} props.sx
 */
const Avatar = forwardRef(
  ({ color = 'default', children, sx, ...other }, ref) => {
    const theme = useTheme()

    if (color === 'default') {
      return (
        <MUIAvatar
          ref={ref}
          sx={sx}
          {...other}
        >
          {children}
        </MUIAvatar>
      )
    }

    return (
      <MUIAvatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
          ...sx
        }}
        {...other}
      >
        {children}
      </MUIAvatar>
    )
  }
)

Avatar.displayName = 'avatar'

export default Avatar
